import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SummaryItem from './SummaryItem.vue'

@Component ({
    components:{ SummaryItem }
})
export default class LeaveSummary extends Vue {
    @Prop({ type: Array }) leaveSummary!: Array<Leave.leaveSummary>
    @Prop({ type: Number }) searchYear!: number

    getTotalData(type: string) {
        let name: any = type;
        let key: keyof Leave.leaveSummary = name;
        let totalResult: number = 0

        this.leaveSummary.forEach((res: Leave.leaveSummary,idx) => { { if(idx<4) totalResult += (res[key] as number )} })
        
        return totalResult;
    }

    get totalRemainDay() {
        let key: string[] = [ 'leaveDay', 'requestedLeaveDay', 'requestedLeaveHalfDay', 'requestedLeaveQuarterDay', 'spentLeaveDay', 'spentLeaveHalfDay', 'spentLeaveQuarterDay' ]
        let totalLeaveDay: number = 0;
        let totalSpentLeaveDay: number = 0;
        let totalRemain: number = 0

        key.forEach((str: string) => {
            if (str == 'leaveDay')
                totalLeaveDay = this.getTotalData(str)
            else
                totalSpentLeaveDay += this.getTotalData(str);
        })

        totalRemain = totalLeaveDay - totalSpentLeaveDay

        return totalRemain;
    }
}