import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import DatePicker from "@appbase/components/DatePicker/DatePicker.vue";
import api from "@hr/api";
import * as dateutil from "@appbase/util/date-helper";
import * as accountTypes from "@account/store/types";
import { mapGetters } from "vuex";

@Component({
    components: { DatePicker },
    computed: mapGetters({
        user: accountTypes.CURRENT,
    }),
})
export default class LeaveAdminHeader extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: String }) searchFilter!: string;
    @Prop({ type: String }) searchWord!: string;
    @Prop({ type: Number }) pageNum!: number;
    @Prop({ type: Number }) rowSize!: number;
    @Prop({ type: String }) pStartDate!: string;
    @Prop({ type: String }) pEndDate!: string;

    @Prop({ type: Boolean }) isPersonView!: boolean;
    @Prop({ type: String, default: "" }) comCode!: string;

    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: G E T T E R S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    user!: account.TCurrent;

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    todayDate: Date = new Date();
    // startDate: string =  dateutil.uGetDateFormat('yyyy-MM-DD', new Date(new Date(this.todayDate.setDate(1)).setMonth(1)));
    // endDate: string =  dateutil.uGetDateFormat('yyyy-MM-DD', new Date(this.todayDate));
    startDate: string = dateutil.uGetDateFormat("yyyy-MM-DD", new Date(this.todayDate));
    endDate: string = dateutil.uGetDateFormat("yyyy-MM-DD", new Date(this.todayDate));
    value: string = "";

    // 연도
    years: Array<string> = [];
    searchYear: string = "0";

    // 휴가 종류
    leaveCode: number = 0;
    codes: Array<any> = [];

    // 휴가 구분
    leaveCategory: string = "all";
    leaveCategories: Array<any> = [];

    // 휴가형태
    leaveType: string = "all";
    leaveTypes: Array<any> = [
        { id: 1, value: "all", name: "휴가형태", nameEng: "All", nameJpn: "All", nameChi: "All" },
        { id: 2, value: "leaveDay", name: "일차", nameEng: "LeaveDay", nameJpn: "LeaveDay", nameChi: "LeaveDay" },
        { id: 3, value: "leaveHalfDay", name: "반차", nameEng: "LeaveHalfDay", nameJpn: "LeaveHalfDay", nameChi: "LeaveHalfDay" },
        { id: 4, value: "leaveQuarterDay", name: "반반차", nameEng: "LeaveQuarterDay", nameJpn: "LeaveQuarterDay", nameChi: "LeaveQuarterDay" },
    ];

    // 휴가상태
    leaveStatus: string = "all";
    leaveStatuses: Array<any> = [
        { id: 1, value: "all", name: "휴가상태", nameEng: "All", nameJpn: "All", nameChi: "All" },
        { id: 2, value: "confirm", name: "승인완료", nameEng: "Confirm", nameJpn: "Confirm", nameChi: "Confirm" },
        { id: 3, value: "proceeding", name: "결재진행중", nameEng: "Proceeding", nameJpn: "Proceeding", nameChi: "Proceeding" },
        { id: 4, value: "cancel", name: "취소", nameEng: "Cancel", nameJpn: "Cancel", nameChi: "Cancel" },
    ];

    // 재직/퇴직
    workingStatus: string = "all";
    workingStatuses: Array<any> = [
        { id: 1, value: "all", name: "재직/퇴직", nameEng: "All", nameJpn: "All", nameChi: "All" },
        { id: 1, value: "working", name: "재직", nameEng: "working", nameJpn: "working", nameChi: "working" },
        { id: 1, value: "retirement", name: "퇴직", nameEng: "retirement", nameJpn: "retirement", nameChi: "retirement" },
    ];

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    async search() {
        if (this.startDate == "" || this.endDate == "") {
            await (window as any).alertAsync(this.$t("기간은_필수_입력입니다").toString());
            return false;
        } else if (dateutil.IEDate(this.startDate) > dateutil.IEDate(this.endDate)) {
            await (window as any).alertAsync(this.$t("시작일이_종료일보다_이후일_수_없습니다").toString());
            return false;
        } else {
            var data = {
                searchFilter: this.searchFilter,
                searchWord: this.value,
                startDate: this.startDate,
                endDate: this.endDate,
                pageNum: 1,
                rowSize: 20,

                leaveCategory: this.leaveCategory,
                leaveCode: this.leaveCode,
                leaveType: this.leaveType,
                leaveStatus: this.leaveStatus,
                workingStatus: this.workingStatus,
            };

            this.$emit("changeYear", this.searchYear == "0" ? new Date().getFullYear() : this.searchYear);
            this.$emit("load", data);
        }
    }

    getExcelList() {
        var searchFilter = this.value == "" ? null : this.searchFilter;
        console.log("@@@ ", this.workingStatus);
        // api.leave.downloadListExcel(searchFilter, this.value, this.startDate, this.endDate, this.leaveCode, this.comCode);

        api.leave.downloadListExcel(
            searchFilter,
            this.searchWord,
            this.startDate,
            this.endDate,
            this.comCode,
            this.leaveCategory,
            this.leaveCode,
            this.leaveType,
            this.leaveStatus,
            this.workingStatus
        );
    }

    created() {
        // 휴가 구분값 변경되어 제외
        api.leave.getLeaveCodes(this.user.company.comCode).then((res) => {
            let codes: any[] = [];
            codes = (res as any[]).map((result: any) => {
                return {
                    id: result.id,
                    name: result.name,
                    nameEng: result.nameEng,
                    nameJpn: result.nameJpn,
                    nameChi: result.nameChi,
                };
            });

            this.codes.push.apply(this.codes, codes);

            //this.codes = res as any[];
            // 휴가구분, 휴가종류, 휴가형태, 상태 기본 값 넣기
            // promise 여러개 호출해서 값 배열에 push하기

            // 사용자 언어 구분법 확인 후 수정 예정
            // this.codes.map(c=>{
            //     if(this.user.userConfig.language.indexOf('EN')>0){
            //         c.name = c.nameEng
            //     }
            // })
        });

        api.leave.getLeaveCategories().then((res) => {
            let categories: any[] = [];
            categories = (res as any[]).map((result: any) => {
                return {
                    id: result.id,
                    value: result.valueCode,
                    name: result.valueName,
                    nameEng: result.valueNameEn,
                    nameChi: result.valueNameCh,
                    nameJpn: result.valueNameJp,
                };
            });
            this.leaveCategories.push.apply(this.leaveCategories, categories);
        });
    }

    mounted() {
        this.initialSearchDate();

        // 휴가 구분 SelectBox 기본값 적용
        this.leaveCategories.push({ id: -1, value: "all", name: "휴가구분", nameEng: "All", nameJpn: "All", nameChi: "All" });
        // 휴가 종류 SelectBox 기본값 적용
        this.codes.push({ id: 0, name: "휴가종류", nameEng: "All", nameJpn: "All", nameChi: "All" });

        // 연도 SelectBox 기본값 적용
        let currentyear = new Date().getFullYear().toString();

        for (let i = -1; i < 2; i++) {
            let year = new Date().getFullYear() - i;
            this.years.push(year.toString());
        }
        this.searchYear = "0";
    }

    @Watch("searchYear")
    changeYear(val: string) {
        if (val != "0") {
            this.startDate = `${this.searchYear}-01-01`;
            this.endDate = `${this.searchYear}-12-31`;
        }
    }

    @Watch("startDate")
    @Watch("endDate")
    selectSearchDate(val: string) {
        if (this.searchYear != "0" && new Date(val).getFullYear().toString() != this.searchYear) this.searchYear = "0";
    }

    @Watch("$route")
    initialSearchDate(val?: any) {
        this.startDate = dateutil.uGetDateFormat("yyyy-MM-DD", new Date(new Date().setMonth(0, 1)));
        this.endDate = dateutil.uGetDateFormat("yyyy-MM-DD", new Date(new Date().setMonth(11, 31)));
    }
}
