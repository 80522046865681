import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper'
import LeaveAdmin from '@hr/components/LeaveAdmin/LeaveAdmin.vue';


@Component({
    components:{LeaveAdmin}
})
export default class LeaveAdminList extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Boolean }) isPersonView!: boolean

  @Prop({ type: Array }) items!: Array<any>

  
  
}
