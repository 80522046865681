import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper'
import * as popupHelper from '@appbase/util/popup-helper';
import modalHelper from '@appbase/util/modal-helper';
import { createEventBus, openPopup, removeEventBus } from '@appbase/util';
import { default as api } from '@hr/api'


@Component
export default class LeaveAdmin extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Object }) item!: any;

  @Prop({ type: Boolean }) isPersonView!: boolean

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //
    status:string='';
    startDate:string = '';
    endDate:string = '';

    EVENT_OPEN_APM: string = 'EVENT_OPEN_APM';


    get statusClass() {
      return {
        'badge': true,
        'flex-shrink-0':true,
        'badge-lightgray': this.item.isCancel==false && this.item.isConfirm==false,
        'badge-green': this.item.isConfirm==true && this.item.isCancel==false,
        'badge-red': this.item.isCancel==true
      }
    }

    get startClass() {
      return {
        'badge': true,
        'flex-shrink-0':true,
        'badge-light-orange2': this.item.startDetail == 'AM',
        'badge-light-blue2': this.item.startDetail == 'PM'
      }
    }

    get endClass() {
      return {
        'badge': true,
        'flex-shrink-0':true,
        'badge-light-orange2': this.item.endDetail == 'AM',
        'badge-light-blue2': this.item.endDetail == 'PM'
      }
    }

    get startTime() {
      let startTime: string = dateutil.uGetDateFormat('H:i',  dateutil.IEDate(this.item.startDate));
      return startTime == '00:00' ? '' : startTime;
    }

    get endTime() {
      let endTime: string = dateutil.uGetDateFormat('H:i',  dateutil.IEDate(this.item.endDate));
      return endTime == '00:00' ? '' : endTime;
    }

    get personId() {
      let personCode: string = this.item.personCode;
      let index: number = personCode.indexOf('_')
      let result: string = personCode.substring(index + 1);

      return result;
    }

    openDetailPopup(){
        const _this = this;
        createEventBus(window, this.EVENT_OPEN_APM, _this.openApmPopup);
        popupHelper.openPopup(`leaveDetail_${this.item.id}`, "/leavePopup/detail",
            {
                id: this.item.id
            },
            {
                width: 450,
                height: 600,
                isFrame: this.isTeams
        });
        
    }

    async openApmPopup(){
      const _this = this;
      var appId = await this.getApplicationId();
      openPopup(`hr_apm_${this.item.docNumber}`, '/workflow/page/form',{
          apmid: this.item.apmId,
          appid: appId,
          storekey: `s_${Date.now().toString()}`,
          pop: 1
      },
      {
          width: 1100,
          height: 1000,
          isFrame: this.isTeams,
          noinvalidateUrl: true
      });
  }

  async getApplicationId(){
      var app = await api.leave.getApplicationInfo({ comCode: this.item.comCode, applicationCode: this.item.docNumber.substr(0,this.item.docNumber.indexOf('-'))})
      return app.applicationId;
  }

  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Watch('item')
  onChangedItem(){
    if(this.item.isCancel){
      this.status = this.$t('취소') as string;
    }else if(this.item.isConfirm){
      this.status = this.$t('승인완료') as string;
    }else{
      this.status = this.$t('결재진행중') as string;
    }

    this.startDate = this.item.startDate.split(' ')[0];
    this.startDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(this.item.startDate));
    this.endDate = this.item.endDate.split(' ')[0];
    this.endDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(this.item.endDate));
  }

  created(){
    this.onChangedItem();
  }
  
}
