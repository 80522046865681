import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import LeaveAdminHeader from "@hr/components/LeaveAdmin/LeaveAdminHeader.vue";
import LeaveAdminList from "@hr/components/LeaveAdmin/LeaveAdminList.vue";
import PageNavigationBar from "@appbase/components/Paginate/PageNavigationBar.vue";
import api from "@hr/api";
import * as dateutil from "@appbase/util/date-helper";
import LeaveSummary from "@hr/components/LeaveAdmin/LeaveSummary.vue";

@Component({
    components: { LeaveAdminHeader, LeaveAdminList, PageNavigationBar, LeaveSummary },
})
export default class LeaveAdminContainer extends Vue {
    @Prop({ type: Boolean }) isPersonView!: boolean;
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    items: Array<any> = [];
    totalCount: number = 0;
    searchFilter: string = "name";
    searchWord: string = "";
    pageNum: number = 1;
    rowSize: number = 20;
    todayDate: Date = new Date();
    // startDate: string =  dateutil.uGetDateFormat('yyyy-MM-DD', new Date(new Date(this.todayDate.setDate(1)).setMonth(1)));
    // endDate: string =  dateutil.uGetDateFormat('yyyy-MM-DD', new Date());
    startDate = dateutil.uGetDateFormat("yyyy-MM-DD", new Date(new Date().setMonth(0, 1)));
    endDate = dateutil.uGetDateFormat("yyyy-MM-DD", new Date(new Date().setMonth(11, 31)));

    // 검색 조건
    leaveCategory: string = "all";
    leaveCode: number = 0;
    leaveType: string = "all";
    leaveStatus: string = "all";
    workingStatus: string = "all";

    comCode: string = "";

    leaveSummary: Array<Leave.leaveSummary> = [];

    // 관리자여부
    isLeaveAdmin: boolean = false;

    searchYear: number = 2023;
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    load(data: any) {
        const { searchFilter, searchWord, pageNum, rowSize, startDate, endDate, leaveCategory, leaveCode, leaveType, leaveStatus, workingStatus } =
            data;

        this.searchFilter = searchFilter;
        this.searchWord = searchWord;
        this.pageNum = pageNum;
        this.rowSize = rowSize;
        this.startDate = startDate;
        this.endDate = endDate;

        api.leave
            .getLeaveAdminList(
                searchFilter,
                searchWord,
                pageNum,
                rowSize,
                startDate,
                endDate,
                this.isPersonView,
                this.comCode,
                leaveCategory,
                leaveCode,
                leaveType,
                leaveStatus,
                workingStatus
            )
            .then((res: any) => {
                if (res) {
                    this.items = res.items;
                    this.totalCount = res.totalCount;
                }
            });

        if (this.isPersonView) this.loadSummaryData();
    }

    pageMove(number: number) {
        var data = {
            searchFilter: this.searchFilter,
            searchWord: this.searchWord,
            pageNum: number,
            rowSize: this.rowSize,
            startDate: this.startDate,
            endDate: this.endDate,
            isPersonReq: this.isPersonView,

            leaveCategory: this.leaveCategory,
            leaveCode: this.leaveCode,
            leaveType: this.leaveType,
            leaveStatus: this.leaveStatus,
            workingStatus: this.workingStatus,
        };
        this.load(data);
    }

    selectType(value: any) {
        this.searchFilter = value;
    }

    selectLeaveCode(value: any) {
        this.leaveCode = value;
    }

    loadSummaryData() {
        api.leave.getLeaveSummary(this.searchYear).then((res: Array<Leave.leaveSummary>) => {
            this.leaveSummary = res;
        });
    }

    changeYear(searchYear: number) {
        this.searchYear = searchYear;
    }
    selectWorkingStatus(value: any) {
        this.workingStatus = value;
    }
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y L E : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    @Watch("isPersonView")
    onQueryChanged() {
        var data = {
            searchFilter: this.searchFilter,
            searchWord: this.searchWord,
            pageNum: this.pageNum,
            rowSize: this.rowSize,
            startDate: this.startDate,
            endDate: this.endDate,
            isPersonReq: this.isPersonView,

            leaveCategory: this.leaveCategory,
            leaveCode: this.leaveCode,
            leaveType: this.leaveType,
            leaveStatus: this.leaveStatus,
            workingStatus: this.workingStatus,
        };
        this.$nextTick(() => {
            this.load(data);
        });

        if (this.isPersonView) {
            this.setPage({ title: this.$t("휴가내역조회") });
        } else {
            this.setPage({ title: this.$t("휴가신청내역") });
        }
    }

    @Watch("$route.query")
    onQueryStringChanged() {
        const { comcode } = this.$route.query;
        // console.log('onQueryStringChanged', comcode)
        if (comcode != undefined) {
            this.comCode = comcode.toString();
        }

        this.pageMove(1);
    }

    @Watch("$route")
    onRouteChanged(val: any) {
        this.isLeaveAdmin = val.name == "leave-admin";
    }

    created() {
        if (this.isPersonView) {
            this.setPage({ title: this.$t("휴가내역조회") });
        } else {
            this.setPage({ title: this.$t("휴가신청내역") });
        }

        const { comcode } = this.$route.query;
        if (comcode != undefined) {
            this.comCode = comcode.toString();
        }
        this.searchYear = new Date().getFullYear();

        var data = {
            searchFilter: this.searchFilter,
            searchWord: this.searchWord,
            pageNum: this.pageNum,
            rowSize: this.rowSize,
            startDate: this.startDate,
            endDate: this.endDate,
            isPersonReq: this.isPersonView,

            leaveCategory: this.leaveCategory,
            leaveCode: this.leaveCode,
            leaveType: this.leaveType,
            leaveStatus: this.leaveStatus,
            workingStatus: this.workingStatus,
        };

        this.load(data);
    }
}
