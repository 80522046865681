import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component ({
})
export default class SummaryItemContainer extends Vue {
    @Prop({ type: Object }) leave!: Leave.leaveSummary;

    get remainDay() {
        let remainDay: number = this.leave.leaveDay - (
            this.leave.requestedLeaveDay
            + this.leave.requestedLeaveHalfDay
            + this.leave.requestedLeaveQuarterDay
            + this.leave.spentLeaveDay
            + this.leave.spentLeaveHalfDay
            + this.leave.spentLeaveQuarterDay
        )
        if (this.leave.leaveCategory == '가불휴가')
            return remainDay*-1
        else
        return remainDay

        
    }

    mounted() {
        if (this.leave.leaveCategory == '가불휴가') {
            this.leave.leaveDay *= -1
            this.leave.requestedLeaveDay *= -1
            this.leave.requestedLeaveHalfDay *= -1
            this.leave.requestedLeaveQuarterDay *= -1
            this.leave.spentLeaveDay *= -1
            this.leave.spentLeaveHalfDay *= -1
            this.leave.spentLeaveQuarterDay *= -1
        }
    }
}