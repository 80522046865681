import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import LeaveAdminContainer from '@hr/containers/LeaveAdminContainer.vue'
@Component({
    components:{
        LeaveAdminContainer
    }
})
export default class LeaveAdminView extends Vue {
    @Prop({ type: Boolean }) isPersonView!: boolean
}
